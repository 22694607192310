import styled from "styled-components";
import { PriceCard, PriceCardDesktop } from "../components/PriceCard";
import { EPlanDuration, IPlan, IUserDetail } from "redux/actions";
import { EThemeType } from "redux/reducers";
import classNames from "classnames";
import {
  FormattedMessage,
} from "react-intl";
import textStyles from "../components/PriceCard/PriceCard.module.scss";
import { useWindowSize } from "hooks/useWindowSize";
interface IProps {
  isLoggedIn?: boolean;
  isSwitch?: boolean;
  plans: IPlan[];
  userDetail?: IUserDetail;
  theme?: EThemeType;
  duration?: EPlanDuration;
  requiredPlan?: IPlan;
  upgradePlanLoading?: boolean;
  onUpgradePlan?: (selectedPlan: IPlan) => void;
  onChange?: (type?: EPlanDuration) => void;
}
export const MobilePricing = ({
  isLoggedIn,
  plans,
  userDetail,
  theme,
  duration,
  requiredPlan,
  upgradePlanLoading,
  onUpgradePlan,
}: IProps) => {
  const Container = styled.div`
    display: none;
    @media screen and (min-width: 1440px) {
      display: flex;
      gap: 16px;
      width: 100%;
      justify-content: center;
      padding:100px 0px;
    }
    @media screen and (min-width: 1024px) {
      display: flex;
      gap: 9px;
      width: 100%;
      justify-content: center;
      padding:80px 0px;
    }
  `;
  const { width } = useWindowSize();
  const isLogin = userDetail?.token ? true : false;
  const IterFont = styled.span`
  font-family: ${({ theme }) => theme.regularFont};
`;

  return (
    <>
      {!isLogin && (
        <Container>
          {plans
            .filter((item) => {
              if (item.name === "Free") {
                return item;
              }
              if (item.duration === "month" && duration === "month") {
                return item;
              }
              if (item.duration === "year" && duration === "year") {
                return item;
              }
            })
            .map((planItem, index) => (
              <PriceCardDesktop
                key={`price-${duration}-${index}`}
                isLoggedIn={isLoggedIn}
                planItem={planItem}
                userDetail={userDetail}
                theme={theme}
                requiredPlan={requiredPlan}
                upgradePlanLoading={upgradePlanLoading}
                onUpgradePlan={onUpgradePlan}
              />
            ))}
        </Container>
      )}
      <div className="block lg:hidden">
        {/* {isSwitch && <PriceSwitch duration={duration} onChange={onChange} />} */}
        {plans
          .filter((item) => {
            if (item.name === "Free") {
              return item;
            }
            if (item.duration === "month" && duration === "month") {
              return item;
            }
            if (item.duration === "year" && duration === "year") {
              return item;
            }
          })
          .map((planItem, index) => (
            <PriceCard
              key={`price-${duration}-${index}`}
              isLoggedIn={isLoggedIn}
              planItem={planItem}
              userDetail={userDetail}
              theme={theme}
              requiredPlan={requiredPlan}
              upgradePlanLoading={upgradePlanLoading}
              onUpgradePlan={onUpgradePlan}
            />
          ))}
        {width <= 768 && isLogin && (
          <div className="max-w-[100%] mx-auto w-full md:px-0">

            <div className="mx-auto flex flex-col items-center leading-8">
              <p
                className={classNames("text-[16px] my-[15px] text-center", {
                  [textStyles.darkStatement]: theme==="dark",
                  [textStyles.lightStatement]: theme==="light",
                })}
              >
                <FormattedMessage
                  id="landing.price.table.bottom"
                  values={{
                    number: (
                      <span className="font-semibold opacity-100!important text-[#2A2831] dark:text-[#FFF]">
                        <IterFont>100000</IterFont>
                      </span>
                    ),
                    price: (
                      <span className="font-semibold opacity-100 text-[#2A2831] dark:text-[#FFF]">
                        <IterFont>$500</IterFont>  
                      </span>
                    ),
                  }}
                />
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

