import { ReactNode } from "react";
import styled from "styled-components";
import { Theme } from "theme";

import Header from "../Header";
import Footer from "../Footer";
import { SectionRefs } from "components/Header/Header";

import { EThemeType } from "redux/reducers";
import { useSelector } from "redux/hooks";
import useRouter from "hooks/useRouter";

const Wrapper = styled.div<
  {
    isAuthPage?:boolean;
    themeMode?: EThemeType;
  } & { theme: Theme }
>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
 background-color: ${({ themeMode, theme, isAuthPage }) => 
    isAuthPage && themeMode === "dark" ? "#2a2831" : themeMode === "dark" ? "#141517" : theme.white};
  color: ${({ themeMode }) =>
    themeMode === "dark" ? "rgba(255,255,255,0.8)" : "#2A2831"};
`;

const Content = styled.div<{ isLanding?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ isLanding }) => (isLanding ? " " : "60px 0px 0 0px")};
`;

interface IProps {
  children?: ReactNode;
  sectionRefs?: SectionRefs;
  isHasBgOnFooter?: boolean;
  activeSection?: string;
  isLanding?: boolean;
}

export const Main = ({
  children,
  sectionRefs,
  isHasBgOnFooter,
  activeSection,
}: IProps) => {
  const theme = useSelector((state) => state.authReducer.theme);
  const { userDetail } = useSelector((state) => state.authReducer);
  const { pathname } = useRouter();

  const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";
  const isLanding = pathname === `/${lang}/` || pathname === `/${lang}`;
  
  const isAuthPage = pathname.includes("/login") || pathname.includes("/signup") || pathname.includes("/email-sent") || pathname.includes("/VerifyEmail");

  return (
    <Wrapper themeMode={theme} isAuthPage={isAuthPage}>
      <Header sectionRefs={sectionRefs} activeSection={activeSection} />
      <Content isLanding={isLanding}>{children}</Content>
      {(!isLanding && !isAuthPage) && (
        <Footer
       isHasBgOnFooter={ isHasBgOnFooter}
        />
        )}
    </Wrapper>
  );
};
