import { useCallback } from "react";
import styles from "./ThemeSwitch.module.scss";
import classNames from "classnames";

import { DarkIcon, LightIcon } from "../Icons";

import { setTheme } from "redux/actions";
import { useSelector } from "redux/hooks";
import { EThemeType } from "redux/reducers";
interface Iprops {
  ThemeDark?: boolean;
  headtheme ?:boolean;
  chatsidebar?: boolean;
}

export const ThemeSwitch = ({ ThemeDark,headtheme , chatsidebar }: Iprops) => {
  const theme = useSelector((state) => state.authReducer.theme);
  const currentTheme = useSelector((state) => state.authReducer.theme);

  const toggleTheme = useCallback(() => {
    if (theme === "dark") setTheme("light");
    else setTheme("dark");
  }, [theme]);

  const onUpdateTheme = (theme: EThemeType) => {
    if (theme !== currentTheme) {
      window.postMessage({ theme });
      setTheme(theme);
    }
  };

  return (
    <div
      className={classNames(styles.switchButton, {
        [styles.dark]: theme === "dark",
        [styles.SidebarDark]: theme === "dark" && ThemeDark === true,
        [styles.light]: theme === "light",
        [styles.chatside]: chatsidebar
      })}
      onClick={toggleTheme}
      data-testid="toggle-theme-change"
    >
      <div
        className={classNames(styles.state, {
          [styles.isActiveLight]: theme === "light" ,
          [styles.SidebarDark]: theme === "dark" && ThemeDark === true,
          [styles.headtheme ]: theme === "light" && headtheme  === true,
        })}
        onClick={() => {
          if (ThemeDark === true) onUpdateTheme("light");
        }}
      >
        <LightIcon/>
      </div>
      <div
        className={classNames(styles.state, {
          [styles.isActiveDark]: theme === "dark" ,
          [styles.SidebarDark]: theme === "dark" && ThemeDark === true,
        })}
        onClick={() => {
          if (ThemeDark === true) onUpdateTheme("dark");
        }}
      >
        <DarkIcon chatSide={theme === "dark" && ThemeDark === true} />
      </div>
    </div>
  );
};
