import { memo } from "react";

import { Input, InputProps } from "../base";
import { EThemeType } from "redux/reducers";
import { ControlLabels, ControlLabelsProps } from "../LabeledControl";

type LabeledInputProps = Pick<
  ControlLabelsProps,
  "success" | "message" | "label" | "labelAdditionLength" | "updateEmail"
> &
  InputProps & { variant?: EThemeType, prompt?:boolean, inputSpace?:boolean, updateEmail?:boolean, loginField?:boolean, UpdateName?: boolean };

const LabeledInputComponent = ({
  error,
  success,
  message,
  label,
  labelAdditionLength,
  focusedStyle = true,
  inputSpace,
  updateEmail,
  UpdateName,
  loginField,
  ...inputProps
}: LabeledInputProps) => (
  <ControlLabels
    error={error}
    success={success}
    message={message}
    label={label}
    labelAdditionLength={labelAdditionLength}
    updateEmail={updateEmail}
  >
    <Input {...inputProps} error={error} focusedStyle={focusedStyle}  inputSpace={ inputSpace} UpdateName={UpdateName} updateEmail={updateEmail} loginField={loginField}/>
  </ControlLabels>
);

export const LabeledInput = memo(LabeledInputComponent);
