export enum RoutePaths {
  Root = "/",

  Login = "login",
  Signup = "signup",
  Referral = "referral/:token",
  EmailSent = "email-sent",
  VerifyEmail = "verify-email",
  VerifyChangeEmail = "user/verify-email-change",
  ConfirmEmailRegistration = "confirm-email-registration",
  VerifySocialAuth = "verify-social-auth",
  AcceptInvitation = "accept-invitation",
  Guide = "guide",
  Terms = "terms",
  Privacy = "privacy",
  Affiliate = "earn-as-affiliate",
  ShareChat = "share-chat/:token",
  
  Chat = "chat",
  Settings = "settings",
  Account = "account",
  Team = "team",
  CurrentPlan = "current-plan",
  Feedback = "feedback",

  Feature = "feature",
  AccessToDiverseModels = "access-to-divers-models",
  ImageCreation = "image-creation",
  DocumentBasedChat = "document-based-chat",
  IntuitiveInterface = "intuitive-interface",
  MultiLanguageCommunication = "multi-language-communication",
  ChromeExtension = "chrome-extension",
  VerifyGoogleSheetAuth = "verify-google-sheet-auth",
}
