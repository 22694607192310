import { TYPES } from "../../types";
import { api } from "../../api";
import { store } from "../../store";
import { setChatModel } from "../chatModels";
import { SetStateAction } from "react";
import { updateCreditLimit, updateCredits } from "../planSubscription";
import {
  ChatSetting,
  ChatType,
  DropdownOption,
  EMessageViewType,
  IBot,
  IChat,
  IChatHistoryResponse,
  IGenerateRelatedQuestionsBody,
  IGetChat,
  IMessage,
  IRemoveGeneratedQuestions,
  ISavePrompt,
  IStopGeneratingMessageBody,
  MessageType,
  RememberBot,
  subOption,
  TextToSpeech,
} from "../chatInterface";

export const startNewChat = () => {
  store.dispatch({ type: TYPES.START_NEW_CHAT, payload: [] });
};

export const askNewChatQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.NEW_CHAT, payload: message });
};

export const answerNewChatQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.ANSWER_NEW_CHAT, payload: message });
};

export const askRegularQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.REGULAR_CHAT, payload: message });
};

export const updateNewRegularQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.UPDATED_NEW_REGULAR_CHAT, payload: message });
};

export const updatedRegularQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.UPDATE_REGULAR_CHAT, payload: message });
};

export const answerRegularQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.ANSWER_REGULAR_CHAT, payload: message });
};

export const setPrivateChat = (thempChat: boolean) => {
  store.dispatch({ type: TYPES.SET_PRIVATE_CHAT, payload: thempChat });
};

export const setRememberSetting = (rememberSetting: boolean) => {
  store.dispatch({
    type: TYPES.SET_REMEMBER_SETTING,
    payload: rememberSetting,
  });
};

export const setRememberHistoryType = (rememberType: string) => {
  store.dispatch({ type: TYPES.SET_REMEMBER_Chat_Type, payload: rememberType });
};

export const setDropDownOptions = (attribute: DropdownOption[]) => {
  store.dispatch({ type: TYPES.SET_DROP_DOWN_OPTIONS, payload: attribute });
};

export const setcontainerHeight = (containerHeight: number) => {
  store.dispatch({
    type: TYPES.SET_CONTAINER_HEIGHT,
    payload: containerHeight,
  });
};

export const setErrordisplay = (drag: boolean) => {
  store.dispatch({ type: TYPES.SET_DRAG_FILE, payload: drag });
};

export const SaveAllChats = (chathistory: IChat[]) => {
  store.dispatch({ type: TYPES.SAVE_ALL_CHATS, payload: chathistory });
};

export const setPagination = (page: number, LastPage: number) => {
  store.dispatch({ type: TYPES.SET_PAGINATION, payload: { page, LastPage } });
};

export const setChatHistoryLoading = (loading: boolean) => {
  store.dispatch({ type: TYPES.SET_CHAT_HISTORY_LOADING, payload: loading });
};

export const deleteAllChats = () => {
  store.dispatch({ type: TYPES.DELETE_ALL_CHATS });
};

export const setViewChatHistory = (viewHistory: boolean) => {
  store.dispatch({ type: TYPES.SET_VIEW_CHAT_HISTORY, payload: viewHistory });
};

export const setSearchWord = (searchWord: string) => {
  store.dispatch({ type: TYPES.SET_SEARCH_WORD, payload: searchWord });
};

export const setSearchQuery = (searchWord: string) => {
  store.dispatch({ type: TYPES.SET_SEARCH_Query, payload: searchWord });
};

export const setSelectesMessageId = (message: number) => {
  store.dispatch({ type: TYPES.SET_Selected_MessageId, payload: message });
};

export const setScrollId = (message: boolean) => {
  store.dispatch({ type: TYPES.SET_Scroll_Id, payload: message });
};

export const deleteSelectedChats = (selectedChatIds: number[]) => {
  store.dispatch({
    type: TYPES.DELETE_SELECTED_CHATS,
    payload: { selectedChatIds },
  });
};

export const deleteSingleChatHistory = (id: number) => {
  store.dispatch({ type: TYPES.DELETE_CHAT_HISTORY, payload: { id: id } });
};

export const addMessage = (newMessage: IMessage, chatId: number) => {
  store.dispatch({ type: TYPES.ADD_MESSAGE, payload: { newMessage, chatId } });
};

export const updateMessage = (newMessage: IMessage, chatId: number) => {
  store.dispatch({
    type: TYPES.UPDATE_MESSAGE,
    payload: { newMessage, chatId },
  });
};

export const regenerateModelNme = (modelName: string, loader: boolean) => {
  store.dispatch({
    type: TYPES.REGENERATE_MODEL_NAME,
    payload: { modelName, loader }
  })
}

export const updateAnswer = (
  message: IMessage,
  chatId: number,
  assistantId: number
) => {
  store.dispatch({
    type: TYPES.UPDATE_ANSWER,
    payload: { message, chatId, assistantId },
  });
};

export const newChatQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.NEW_CHAT_QUESTION, payload: message });
};

export const removeChatQues = () => {
  store.dispatch({ type: TYPES.REMOVE_CHAT_QUESTION, payload: [] });
};

export const updateNewChatQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.UPDATE_CHAT_QUESTION, payload: message });
};

export const AddHistoryMessage = (chatId: number) => {
  store.dispatch({ type: TYPES.ADD_MESSAGE_HISTORY, payload: chatId });
};

export const updateAssistantAnswer = (
  message: IMessage,
  assistantId: number
) => {
  store.dispatch({
    type: TYPES.UPDATE_ASSISTANT_ANSWER,
    payload: { message, assistantId },
  });
};

export const setIsLoadMoreMessages = (isloadMore: boolean) => {
  store.dispatch({ type: TYPES.LOAD_MESSAGES, payload: isloadMore });
};

export const clearhistoryMessages = () => {
  store.dispatch({ type: TYPES.CLEAR_HISTORY_MESSAGES });
};

export const setMessagesPagination = (page: number, lastPage: number) => {
  store.dispatch({
    type: TYPES.MESSAGES_PAGINATION,
    payload: { current_page: page, last_page: lastPage },
  });
};

export const updateLanguageOptions = (filterlanguage: subOption[]) => {
  store.dispatch({
    type: TYPES.UPDATE_DROP_DOWN_OPTIONS,
    payload: filterlanguage,
  });
};

export const setAdminAccountDeleted = (errorMessage: boolean) => {
  store.dispatch({ type: TYPES.SET_ADMIN_DELETED, payload: errorMessage });
};

export const getAllChats = ({
  search,
  page,
  perPage,
}: {
  search?: string;
  page?: number;
  perPage?: number;
}) => {
  return new Promise((resolve, reject) => {
    const url =
      page && perPage
        ? `/api/chat?search=${search}&page=${page}&perPage=${perPage}`
        : `/api/chat?search=${search}`;
    api
      .get(url)
      .then((res: any) => {
        resolve(res.data.chats);
        store.dispatch({
          type: TYPES.GET_ALL_CHATS_HISTORY,
          payload: res?.data?.chats?.data,
        });
        store.dispatch({ type: TYPES.GET_ALL_CHATS, payload: res.data.chats });
      })
      .catch((err: any) => {
        reject(err);
        store.dispatch({ type: TYPES.GET_ALL_CHATS, payload: [] });
      });
  });
};

export const addNewChatHistory = (chat: IChat) => {
  store.dispatch({ type: TYPES.ADD_CHAT_HISTORY, payload: chat });
};

export const updateChatHistory = (id: number, topic: string) => {
  return new Promise((resolve, reject) => {
    api
      .put(`/api/chat/${id}`, { topic: topic })
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.UPDATE_CHAT_HISTORY,
          payload: { id, topic },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteChatHistory = (id: number) => {
  return new Promise((resolve, reject) => {
    api
      .delete(`/api/chat/${id}`)
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.DELETE_CHAT_HISTORY,
          payload: { id: id },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getChatHistory = (chatId: number, options?: { signal?: AbortSignal }, page?: number) => {
  return new Promise((resolve, reject) => {
    const url = !page
      ? `/api/chat/history/${chatId}`
      : `/api/chat/history/${chatId}?page=${page}`;
    api
      .get(url, { signal: options?.signal })
      .then((res: any) => {
        const response: IChatHistoryResponse = res.data;
        const { chat, messages } = response;

        let messagesList = messages;
        if (
          chat.files ||
          (messages.data[0]?.files &&
            messages.data[0]?.files.length > 0 &&
            messages.data[0]?.type === "assistant")
        ) {
          const newMessage = {
            id: messagesList.data[0].id,
            type: MessageType.user,
            images: [],
            files: messages.data[0]?.files,
          };
          if (messagesList.data[0]?.type === "assistant") {
            messagesList.data.unshift(newMessage);
          }
        }

        messagesList.data = messagesList.data.map((message: any) => {
          return {
            ...message,
            chatType: chat.chat_type, // Add the chatType from the chat object
          };
        });

        const updatedResponse = { chat, messages: messagesList.data };

        resolve(updatedResponse);
        store.dispatch({
          type: TYPES.GET_CHAT_HISTORY,
          payload: messagesList.data,
        });

        if (!page) {
          store.dispatch({
            type: TYPES.MESSAGES_PAGINATION,
            payload: {
              current_page: messagesList.current_page,
              last_page: messagesList.last_page,
            },
          });
        } else {
          store.dispatch({
            type: TYPES.UPDATE_MESSAGES_PAGINATION,
            payload: {
              current_page: messagesList.current_page,
            },
          });
        }

        setTimeout(() => {
          if (chat?.model) {
            let Model = chat.model;
            if (Model && typeof Model.type === "string") {
              Model.type = JSON.parse(Model.type);
            }
            if (Model && typeof Model.attributes === "string") {
              Model.attributes = JSON.parse(Model.attributes);
            }
            setChatModel(Model);
          }
        }, 300);

        resolve(updatedResponse);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const stopGeneratingResponse = (data: IStopGeneratingMessageBody) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/chat/message/stop", data)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getNewChatTopic = (chatId: number) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/chat/${chatId}`)
      .then((res: any) => {
        addNewChatHistory(res.data);
        resolve(res.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const generatePresignedURL = (data: {
  name: string;
  type?: null | typeof ChatType.document | typeof ChatType.image_chat;
  signal?: AbortSignal;
}) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        "/api/generate-s3-url",
        { name: data.name, type: data?.type },
        { signal: data.signal }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveFilePath = ({
  path,
  name,
  token,
  message,
  setISUploadURL,
  uploadUrl,
  resetMessage,
  signal,
  model,
  setIsFileUploading,
}: {
  path: string;
  name: string;
  token: string | undefined;
  message?: string;
  setISUploadURL?: React.Dispatch<SetStateAction<boolean>>;
  uploadUrl?: boolean;
  resetMessage?: () => void;
  signal?: AbortSignal;
  model: string;
  setIsFileUploading?: React.Dispatch<SetStateAction<boolean>>;
}) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_BASEURL}/api/chat/file`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      signal: signal,
      body: JSON.stringify({ path, name, message, model }),
    })
      .then(async (response: any) => {
        if (!response.ok) {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message || `Something went wrong`;
          return reject(new Error(errorMessage));
        }
        if (!uploadUrl) {
          setIsFileUploading!(false);
        }
        if (uploadUrl) {
          setISUploadURL!(false);
          resetMessage!();
        }

        const reader = response?.body?.getReader();
        const decoder = new TextDecoder();
        let i = 0;
        let content = "";
        let texts = "";
        let isJsonParsed = false;
        let jsonData = null;
        while (true) {
          const streamResult = await reader?.read();
          const decoderText = decoder.decode(streamResult?.value);
          content += decoder.decode(streamResult?.value || new Uint8Array(), {
            stream: !streamResult?.done,
          });
          let completeMessage = decoderText;
          while (!isJsonParsed) {
            let leftBracket = content.indexOf("{");
            let rightBracket = content.lastIndexOf("}");
            if (leftBracket === -1 || rightBracket === -1) {
              break;
            }
            if (leftBracket !== -1 && rightBracket !== -1) {
              const jsonDataString = content.substring(
                leftBracket,
                rightBracket + 1
              );
              const jsonData = JSON.parse(jsonDataString);
              if (jsonData.chat) {
                texts = content.substring(rightBracket + 1);
                isJsonParsed = true;
              }
            }
            completeMessage = content.substring(leftBracket, rightBracket + 1);
            content = content.substring(rightBracket + 1);
          }
          if (streamResult?.done) {
            resolve({ jsonData, content });
            break;
          }
          if (i === 0) {
            try {
              jsonData = JSON.parse(
                completeMessage && texts !== "" ? completeMessage : decoderText
              );
              completeMessage = "";

              if (jsonData) {
                updateCredits(jsonData.credits);
                updateCreditLimit({
                  daily_limit: jsonData.daily_limit,
                  used_today: jsonData.used_today,
                });
                store.dispatch({
                  type: TYPES.SAVE_FILE_PATH,
                  payload: {
                    chatItem: {
                      id: jsonData?.chat?.id,
                      chat_type: jsonData?.chat?.chat_type,
                      topic: jsonData?.chat?.topic,
                      is_topic_updated: jsonData?.chat?.is_topic_updated,
                      document_index_name: jsonData?.chat?.document_index_name,
                      user_id: jsonData?.chat?.user_id,
                      created_at: jsonData?.chat?.created_at,
                      updated_at: jsonData?.chat?.updated_at,
                      file: jsonData.file,

                      last_assistant_message: {
                        id: jsonData?.chat?.id,
                        content: content,
                        type: "assistant",
                        created_at: jsonData?.chat?.created_at,
                        updated_at: jsonData?.chat?.updated_at,
                        chat_id: jsonData?.chat?.id,
                      },
                    },
                    message: [
                      ...(message
                        ? [
                          {
                            id: jsonData?.message?.id,
                            content: message,
                            type: "user",
                            images: [],
                            file: jsonData.file,
                          },
                        ]
                        : [
                          {
                            id: jsonData?.assistant_message?.id,
                            type: "user",
                            images: [],
                            file: jsonData.file,
                          },
                        ]),
                      {
                        id: jsonData?.assistant_message?.id,
                        content: content,
                        type: "assistant",
                        created_at: jsonData?.chat?.created_at,
                        updated_at: jsonData?.chat?.updated_at,
                        chat_id: jsonData?.chat?.id,
                        file: jsonData.file,
                      },
                    ],
                  },
                });
              }
            } catch (error: any) {
              console.error("Error parsing JSON:", error);
            }
          } else {
            if (jsonData) {
              store.dispatch({
                type: TYPES.SAVE_FILE_PATH,
                payload: {
                  chatItem: {
                    id: jsonData?.chat?.id,
                    chat_type: jsonData?.chat?.chat_type,
                    topic: jsonData?.chat?.topic,
                    is_topic_updated: jsonData?.chat?.is_topic_updated,
                    document_index_name: jsonData?.chat?.document_index_name,
                    user_id: jsonData?.chat?.user_id,
                    created_at: jsonData?.chat?.created_at,
                    updated_at: jsonData?.chat?.updated_at,
                    file: jsonData.file,

                    last_assistant_message: {
                      id: jsonData?.chat?.id,
                      content: content,
                      type: "assistant",
                      created_at: jsonData?.chat?.created_at,
                      updated_at: jsonData?.chat?.updated_at,
                      chat_id: jsonData?.chat?.id,
                    },
                  },
                  message: [
                    ...(message
                      ? [
                        {
                          id: jsonData?.message?.id,
                          content: message,
                          type: "user",
                          images: [],
                          file: jsonData.file,
                        },
                      ]
                      : [
                        {
                          id: jsonData?.assistant_message?.id,
                          type: "user",
                          images: [],
                          file: jsonData.file,
                        },
                      ]),
                    {
                      id: jsonData?.assistant_message?.id,
                      content: content,
                      type: "assistant",
                      created_at: jsonData?.chat?.created_at,
                      updated_at: jsonData?.chat?.updated_at,
                      chat_id: jsonData?.chat?.id,
                      file: jsonData.file,
                    },
                  ],
                },
              });
            }
          }
          i++;
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const removeRelatedQuestions = ({
  messageId,
  messageViewType,
}: IRemoveGeneratedQuestions) => {
  if (messageViewType === "new") {
    store.dispatch({
      type: TYPES.REMOVE_RELATED_QUESTIONS_FROM_NEW_MESSAGES,
      payload: messageId,
    });
  }
  if (messageViewType === "history") {
    store.dispatch({
      type: TYPES.REMOVE_RELATED_QUESTIONS_FROM_MESSAGES,
      payload: messageId,
    });
  }
};

export const generateRelatedQuestions = ({
  chatId,
  messageViewType,
  signal,
}: IGenerateRelatedQuestionsBody & { signal?: AbortSignal }) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/chat/related-questions", { chat_id: chatId }, { signal })
      .then((res) => {
        resolve(res.data);
        if (messageViewType === "new") {
          store.dispatch({
            type: TYPES.GET_GENERATED_QUESTIONS_NEW,
            payload: res.data,
          });
        }
        if (messageViewType === "history") {
          store.dispatch({
            type: TYPES.GET_GENERATED_QUESTIONS_HISTORY,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const removeLastMessage = (messageViewType: EMessageViewType) => {
  if (messageViewType === "new") {
    store.dispatch({
      type: TYPES.REMOVE_LAST_MESSAGE_FROM_NEW_MESSAGES,
    });
  }
  if (messageViewType === "history") {
    store.dispatch({
      type: TYPES.REMOVE_LAST_MESSAGE_FROM_MESSAGES,
    });
  }
};

export const getAllPropts = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/prompts`)
      .then((res) => {
        store.dispatch({
          type: TYPES.GET_ALL_PROMPT,
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const SavePrompts = (data: ISavePrompt) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/prompts/user", data)
      .then((res) => {
        getSavePrompts();
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSavePrompts = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/prompts/user`)
      .then((res) => {
        store.dispatch({
          type: TYPES.GET_USER_PROMPT,
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteUserPRompts = (id: number) => {
  return new Promise((resolve, reject) => {
    api
      .delete(`/api/prompts/user/${id}`)
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.DELETE_USER_PROMPT,
          payload: { id: id },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateUserPrompt = (id: number, data: ISavePrompt) => {
  return new Promise((resolve, reject) => {
    api
      .put(`/api/prompts/user/${id}`, data)
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.UPDATE_USER_PROMPT,
          payload: { id, data },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const ShareChat = ({
  chat_id,
  messages,
}: {
  chat_id?: number;
  messages?: [];
}) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/chat/share", { chat_id, messages })
      .then((res: any) => {
        const token = res.data?.token;
        const baseURL = window.location.origin;
        resolve(res.data);
        const path = `${baseURL}/share-chat/${token}`;
        resolve(path); // Simply resolve with the path
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getShareChat = (data: IGetChat) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/shared-chat/${data?.token} `)
      .then((res: any) => {
        resolve(res.data);
        const chatData = res.data; // Extract chat data from the API response
        const messages = chatData.messages; // Extract messages array

        // Check the condition for the first message in the messages array
        if (
          messages[0]?.files &&
          messages[0]?.files.length > 0 &&
          messages[0]?.type === "assistant"
        ) {
          // Create a new message object
          const newMessage = {
            id: messages[0].id,
            type: "user",
            images: [],
            files: messages[0]?.files,
          };
          if (messages[0]?.type === "assistant") {
            messages.unshift(newMessage);
          }
        }

        // Updated chat data
        const updatedChatData = {
          ...chatData,
          messages, // Updated messages array
        };
        store.dispatch({
          type: TYPES.GET_SHARE_CHAT,
          payload: updatedChatData,
        });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const CopyShareChat = (data: IGetChat) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/shared-chat/copy", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getChatSettings = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/user-setting")
      .then((res) => {
        resolve(res?.data);
        store.dispatch({
          type: TYPES.GET_CHAT_SETTING,
          payload: res?.data?.chat_settings,
        });
        store.dispatch({
          type: TYPES.GET_ROLL_BOT_SETTING,
          payload: res?.data?.bot_settings,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const SetChatSetting = (data: ChatSetting | RememberBot) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/user-setting", data)
      .then((res) => {
        getChatSettings().catch();
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const DeleteS3Link = (s3Link: string) => {
  return new Promise((resolve, reject) => {
    api
      .delete("api/s3-file", {
        data: { file_path: s3Link },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const removeMultipleChat = (chat_ids: number[]) => {
  return new Promise((resolve, reject) => {
    api
      .delete(`/api/chat/delete-multiple`, {
        data: { chat_ids },
      })
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.DELETE_MULTIPLE_CHAT_HISTORY,
          payload: { chat_ids },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteAllConversation = () => {
  return new Promise((resolve, reject) => {
    api
      .delete(`/api/chat/clear-all`)
      .then((res) => {
        resolve(res.data);
        getAllChats({ search: "" });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const RoleBot = (data: IBot) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/user-setting", data)
      .then((res: any) => {
        resolve(res.data);
        getChatSettings();
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const filterLanguages = (model: string) => {
  return new Promise((resolve, reject) => {
    api
      .get(`api/user-setting?model=${model}`)
      .then((res) => {
        resolve(res?.data?.bot_settings_allowed);
        store.dispatch({
          type: TYPES.UPDATE_DROP_DOWN_OPTIONS,
          payload: res?.data?.bot_settings_allowed,
        });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const AudioPlayer = (
  data: TextToSpeech,
  token: string | undefined,
  abortSignal: AbortSignal,
): Promise<ReadableStreamDefaultReader<Uint8Array> | undefined> => {
  return new Promise((resolve, reject) => {
    // Create the URL with team_id as a query parameter
    const url = new URL(`${process.env.REACT_APP_API_BASEURL}/api/chat/text-to-speech`);
    const userDetail = store.getState().authReducer.userDetail; // Assuming userDetail is available in your Redux store
    if (userDetail?.user?.team?.id) {
      // Append team_id to the URL search params
      url.searchParams.append("team_id", userDetail?.user?.team?.id.toString());
    }

    fetch(url
      , {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
        signal: abortSignal,
      })
      .then((response) => {
        if (!response.ok) {
          return reject(`Error: ${response.status} ${response.statusText}`);
        }
        const reader = response.body?.getReader();
        resolve(reader)
      })
      .catch((err) => {
        if (err.name === 'AbortError') {
          console.error("Fetch aborted");
          return;
        }
        reject(err);
      });
  });
};



